import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import Project from './project'

const Projects = ({
  projects,
}) => (
  <ul>
    {projects.map(id => (
      <Project
        key={id}
        id={id}
      />
    ))}
  </ul>
)

Projects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.string),
}

Projects.defaultProps = {
  projects: [],
}

export default component()(Projects)
