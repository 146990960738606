export const load = (action, store, { graphql }) => {
  const QUERY_PROJECTS = `projects {
    id
    code
    name
    logo
  }`

  graphql.query(`{
    ${QUERY_PROJECTS}
  }`)
}

export const set = ({ payload }, store) => (
  store.data.projects.set(payload)
)
