import { container } from '@k-mille/ui-components'
import Component from './project'

const mapStore = (store, { id }) => ({
  ...store.data.projects.get(id),
})

export default container({
  mapStore,
})(Component)
