import React from 'react'
import PropTypes from 'prop-types'
import { component, Button, Input } from '@k-mille/ui-components'

const formName = 'project'

const getInputType = (gqlType) => {
  switch (gqlType) {
    case 'Float': return 'number'
    default: return 'text'
  }
}

const graphqlToInput = (prefix = '') => (field) => {
  const properties = {
    name: `${prefix}${field.replace(/:.*/, '')}`,
    placeholder: `${prefix}${field.replace(/:.*/, '')}`,
    type: getInputType(field.replace(/.*: /, '').replace('!', '')),
  }

  if (field.includes('!')) properties.required = true

  return properties
}

const fields = [ // this are GQL types
  'code: String!',
  'name: String!',
  'postalCode: String!',
  'city: String!',
  'address: String!',
  'type: String!',
  'intraCommunityTvaNumber: String',
  'siren: String',
  'capital: Float',
  'logo: String',
  'bic: String',
  'iban: String',
]
  .map(graphqlToInput())
  .concat(
    [
      'invoicesGoogleSheetId: String',
      'invoicesGoogleSheetRange: String',
      'expensesGoogleSheetId: String',
      'expensesGoogleSheetRange: String',
    ].map(graphqlToInput('parameters.')),
  )

const CreateProject = ({
  onSave,
}) => (
  <form>
    {fields.map(field => (
      <Input
        key={field.name}
        formName={formName}
        {...field}
      />
    ))}

    <Button
      primary
      type="submit"
      onClick={onSave}
    >
      Save
    </Button>
  </form>
)

CreateProject.propTypes = {
  onSave: PropTypes.func,
}

CreateProject.defaultProps = {
  onSave: undefined,
}

export default component()(CreateProject)
