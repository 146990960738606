import { when } from 'k-ramel'
import {
  load,
  set,
} from './projects.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>main')(load),
  when('@@graphql/projects>ENDED')(set),
]
