import { container } from '@k-mille/ui-components'
import listeners from './createProject.listeners'
import Component from './createProject'

const name = 'create-project'

const mapStore = store => ({
  onSave: (e) => {
    e.preventDefault()

    store.dispatch('@@ui>PROJECT>SAVE')
  },
})

export default container({
  name,
  isScreen: true,
  listeners,
  mapStore,
})(Component)
