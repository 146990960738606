export default {
  main: {

  },

  logo: {
    maxWidth: '5em',
    maxHeight: '5em',
    backgroundColor: '#c6c6c6',
  },

  info: {
    display: 'flex',
  },

  value: {
    fontWeight: 'bold',
    marginLeft: '.5em',
  },
}
