export const save = (action, store, { form, graphql }) => {
  const project = form('project').get()

  // force parameters
  Object.entries(project)
    .forEach(([key, value]) => {
      if (!key.includes('parameters.')) return

      delete project[key]
      project.parameters = {
        ...project.parameters,
        [key.split('.')[1]]: value,
      }
    })

  // force types
  if (project.capital) project.capital = +project.capital


  const MUTATION_ADD_PROJECT = `
    AddProject ($project: InputProject!) {
      addProject(input: $project) {
        id
        code
        name
      }
    }
  `

  graphql.mutation(MUTATION_ADD_PROJECT, { project })
}

export const add = ({ payload }, store) => {
  store.data.projects.add(payload)
}

export const redirect = (action, store, { router }) => {
  router.push('main')
}
