import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './project.styles'

const Project = ({
  classes,
  code,
  name,
  logo,
}) => (
  <div className={classes.main}>
    <img
      className={classes.logo}
      src={logo}
      alt={name}
    />

    <div className={classes.info}>
      <div>Code</div>
      <div className={classes.value}>{code}</div>
    </div>
    <div className={classes.info}>
      <div>Name</div>
      <div className={classes.value}>{name}</div>
    </div>
  </div>
)

Project.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
}

Project.defaultProps = {
  code: undefined,
  name: undefined,
  logo: undefined,
}

export default component({ styles })(Project)
